import axios from 'axios'
import store from '@/store'

export const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  withCredentials: true, // required to handle the CSRF token
});
/*
 * Add a response interceptor
 */
client.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
		console.log(error);
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters["auth/isLoggedIn"]
    ) {
      store.dispatch("auth/logout");
			router.push('/login');
    }
    return Promise.reject(error);
  }
);

export default {
	async getUser() {
		return client.get('/api/user').then(response => {
			let user = response.data;
			store.dispatch('auth/syncUser', { user });
		});
	},
	async updateUser(data) {
		return await client.post('/api/user/update', data);
	},
	async updateUserBtns(btnIds) {
		client.post('/api/user/update-btns', { btnIds });
	},
	async requestSupport(data) {
		return await client.post('/api/user/support', data);
	},
	async getInvites() {
		return await client.get('/api/user/invites');
	},
	async getMyInvite() {
		return await client.get('/api/user/my-invite');
	},
	async sendInvite(data) {
		return await client.post('/api/user/send-invite', data);
	},
	async validateInvite(data) {
		await client.get('/sanctum/csrf-cookie');
		return await client.post('/api/validate-invite', data);
	},
	async cancelInvite(id) {
		return await client.post(`/api/user/cancel-invite`, { id });
	},
	async acceptInvite(id) {
		return await client.post(`/api/user/accept-invite`, { id });
	},
	async declineInvite(id) {
		return await client.post(`/api/user/decline-invite`, { id });
	},
}