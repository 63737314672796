<template>
	<loader></loader>
	<div :class="{'bg-dark': route.name === 'register'}">
		<Header></Header>
		<div>
			<router-view/>
			<user-menu></user-menu>
		</div>
		<Footer></Footer>
	</div>
	<instructions />
</template>

<script setup>
import Loader from '@/components/Loader';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Instructions from '@/components/Instructions';
import UserMenu from '@/components/UserMenu';

import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<style lang="scss">
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
	opacity: 0;
}

*,*:before,*:after {
	box-sizing: border-box;
}

body {
	background-color: $black;
	font-family: 'Roboto', Arial, Helvetica, sans-serif;
	font-size: 14px;
	margin: 0;
}
#app {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: flex-start;
	position: relative;
	min-width: 320px;

	& > div {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 510px;
		position: relative;
		width: 100%;

    &.bg-dark {
      background-color: $white;
    }

		& > * {
			max-width: 510px;
			width: 100%;
		}

		& > div {
			overflow: hidden;
			position: relative;

			& > div {
				min-height: 450px;
				padding: 60px 0 20px 0;

				@media screen and (max-width: 500px) {
					padding-bottom: 50px;
				}
			}
		}
	}

}
main {
	background-color: white;
	border-radius: 20px;
	max-width: 510px;
	padding: 1rem;
	width: 100%;
}

a {
	color: $primary;

	&.button {
		text-decoration: none;
	}
}
button, .button {
	cursor: pointer;
	outline: 0;
}

h1 {
	margin-top: 0;
	margin-bottom: 1rem;
}

label {
	display: block;
	margin-bottom: 1rem;
}

.text-center {
	text-align: center;
}

form button, .button {
	background-color: $primary;
	border: 0;
	border-radius: 9px;
	color: white;
	cursor: pointer;
	min-width: 115px;
	padding: .75rem;
	text-align: center;

	i.fal, i.fa {
		transform: translateY(1px);
	}

	&[disabled] {
		background-color: lighten($primary, 35%);
		cursor: not-allowed;
	}
}


section > form {
	align-items: center;
	display: grid;
	grid-template-columns: auto auto;
	justify-content: space-between;

	h1 {
		grid-column: 1 / span 2;
		text-align: center;
	}

	& > hr {
		grid-column: 1 / span 2;
		margin-top: 0;
		width: 100%;
	}

	label {
		grid-column: 1 / 3;
		
		small:last-child {
			display: inline-block;
			text-align: center;
			width: 100%;
		}

		&.error {
			color: darkred;

			input {
				&:not([type="checkbox"],[type="radio"]) {
					border: 2px solid darken(red, 10%);
				}
			}
		}
	}
}

footer {
	p {
		color: white;
		margin: .5rem 0;
		text-align: center;
	}
}
</style>
