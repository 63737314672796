<template>
  <mini-load :show="loader"></mini-load>
  <div id="paypal-buttons"></div>
</template>

<script setup>
import { loadScript } from '@paypal/paypal-js';
import { onMounted, defineProps, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import AuthService from '@/services/AuthService';
import BillingService from "@/services/BillingService";
import PageService from '@/services/PageService';

const loader = ref(false);
const store = useStore();
const router = useRouter();
const props = defineProps(['billing']);

const createSubscription = (data, actions) => {
  loader.value = true;
  return actions.subscription.create({
    plan_id: props.billing.selectedPlan.id,
    application_context: {
      shipping_preference: 'NO_SHIPPING',
    }
  });
};


const onApprove = async (data, actions) => {
  let subscriptionId = data.subscriptionID || null;

  try {
    await BillingService.createSubscription({ subscriptionId });
  } catch(err) {
    await store.dispatch('setMsg', { resp: err.response });
  }
  await store.dispatch('auth/syncUser');

  await store.dispatch('setMsg', { msg: { text: 'Your purchase was completed', status: true } });
  loader.value = false;
};
const onError = err => {
  console.error(err);
  let resp = { text: 'There was a problem with your payment', status: 402 };
  store.dispatch('setMsg', { resp });

  loader.value = false;
};

let paypal;
const loadPaypalButtons = async () => {
  try {
    paypal = await loadScript({
      'client-id': process.env.VUE_APP_CLIENT_ID,
      'vault': true,
      'intent': 'subscription'
    });
  } catch (error) {
    console.error("failed to load the PayPal JS SDK script", error);
  }

  if (paypal) {
    try {
      await paypal.Buttons({ createSubscription, onApprove, onError }).render("#paypal-buttons");
      loader.value = false;
    } catch (error) {
      console.error("failed to render the PayPal Buttons", error);
    }
  }
}

onMounted(() => loadPaypalButtons());
</script>