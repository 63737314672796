import axios from 'axios';
import { useStore } from 'vuex';

const store = useStore();

export const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
client.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
		console.log(error);
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters["auth/isLoggedIn"]
    ) {
      store.dispatch("auth/logout");
			router.push('/login');
    }
    return Promise.reject(error);
  }
);

export default {
	async plans() {
		let res = await client.get('/api/plans');
		return res.data;
	},
	async checkDiscount(data) {
		return await client.post('/api/check-discount', data);
	},
	async confirmFreePackage(data) {
		return await client.post('/api/user/confirm-free-package', data);
	},
	async savePayment(data) {
		return await client.post('/api/user/save-payment', data);
	},
	async payments() {
		let res = await client.get('/api/user/payments');
		return res.data;
	},
	async transactions() {
		let res = await client.get('/api/user/subscription/transactions');
		return res.data;
	},
	async updateBilling(data) {
		return await client.post('/api/user/update-billing', data);
	},
	async cancelSubscription() {
		return await client.post('/api/user/subscription/cancel');
	},
	async checkSubscriptionStatus() {
		let res = await client.get('/api/user/subscription/status');
		return res.data;
	},
	async createSubscription(data) {
		return await client.post('/api/user/subscription/create', data);
	},
	async updateSubscription(data) {
		return await client.post('/api/user/subscription/update', data);
	}
}