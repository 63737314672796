<template>
	<h3>
		<slot>
			<span v-html="page.title"></span>
			{{ page.max > 1 ? ' - ' + page.page_sort + ' of ' + page.max : '' }}
			<sup v-if="page.slug == 'home'" >&copy;</sup>
		</slot>
		<br>
		<small v-if="(page.title ?? '').indexOf('IPA') > -1">
			(International Phonetic Alphabet)
		</small>
		<small v-else-if="page.slug == 'home' && currentRouteName == 'home'" class="home-subtitle">
			<hr><span>Mastering</span> the American Accent
		</small>
		<small v-else-if="page.slug == 'speaking_clearly' && currentRouteName == 'home'">
			To Be Easily Understood
		</small>
		<small v-else-if="page.slug == 'speaking_fluently' && currentRouteName == 'home'">
			To Be Empowered
		</small>
		<small v-else-if="page.slug == 'vowels' && currentRouteName == 'home'">
			Master The 14 Vowel Sounds
		</small>
		<small v-else-if="page.slug == 'consonants' && currentRouteName == 'home'">
			Master The 24 Consonant Sounds
		</small>
		<small v-else-if="page.slug == 'intonation' && currentRouteName == 'home'">
			Master Stress, Rhythm, &amp; Melody
		</small>
	</h3>
</template>


<script setup>
	import { computed } from 'vue';
	import { useStore } from 'vuex';
	import { useRoute } from 'vue-router';
	const store = useStore();
	const page = computed(() => store.getters['program/getPage']);
	const router = useRoute();
	const currentRouteName = computed(() => router.name);
</script>

<style lang="scss" scoped>
	h3 {
		font-size: 1.5rem;
		text-align: center;
		width: 100%;
		
		small {
			font-size: 1rem;
		}

		sup {
			color: $primary;
			font-size: 1rem;
		}

		.home-subtitle {
			color: $primary;

			span {
				border-bottom: 1px solid $black;
				display: inline-block;
			}
		}

		@media screen and (max-width: 500px) {
			margin-top: .5rem;
			margin-bottom: .75rem;
		}
	}
</style>