<template>
	<div :class="'msg-box ' + (msg.status ? 'success' : 'error')" v-if="msg.text">{{ msg.text }}</div>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const msg = computed(() => store.getters.getMsg);
</script>
<style lang="scss" scoped>
.msg-box {
	word-break: break-all;
	grid-column: 1 / 3;
	text-align: center;

	position: relative;
	padding: 1rem 1rem;
	margin-top: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;

	&.error {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
	}
	&.success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
	}
}
</style>