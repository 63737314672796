<template>
	<div class="menu">
		<page-title />
		<ul>
			<li v-if="page.slug == 'home'">
				<button @click="store.dispatch('program/showInstructions', true)"><span class="menu-number">1</span> Getting Started</button>
			</li>
			<li v-for="child, idx in page.children" :key="child.id">
				<button @click="store.dispatch('program/changePage', child.id)" 
					v-html="(page.slug == 'home' ? `<span class='menu-number'>${idx + 2}</span>` : '') + child.title">
				</button>
			</li>
			<li v-if="page.slug == 'home'">
				<button @click="() => { showNatural = !showNatural }"><span class="menu-number">{{ page.children.length + 2 }}</span> Speaking Naturally</button>
			</li>
			<!-- <li>
				<button>Awarding Certificate</button>
			</li> -->
		</ul>
		<description />
	</div>
	<div id="naturally" v-if="page.slug === 'home' && showNatural">
		<button @click="() => { showNatural = !showNatural }" class="button" title="Close"><i class="fal fa-times"></i></button>
		<h4>Speaking Naturally in Conversational Speech</h4>
		<p>This advanced module will expand your ability to speak comfortably, confidently, and naturally in longer conversations, i.e., when making oral reports, presentations, and engaging in conversations requiring longer continuous speech.</p>
		<p>The recommended method is to record and compare your running speech in longer sentences and paragraphs.</p>
		<ol>
				<li>Return to both the "Speaking Clearly" and "Speaking Fluently" modules.</li>
				<li>Select a paragraph. Listen intently, record the entire paragraph. Listen to the entire paragraph again to compare. Pay special attention to your pronunciation and the smoothness of your speech. Are you stressing words correctly? Is your voice rising or lowering to match the native speaker?</li>
				<li>Practice recording every paragraph several times.</li>
				<li>If you are having difficulty with your pronunciation, return to the lessons and exercises in "Speaking Clearly."</li>
				<li>If you are having trouble with the smoothness of your speech, return to "Speaking Fluently."</li>
				<li>When your recordings of these paragraphs match the native speaker, you will have mastered the American accent and achieved the level of an Advanced Speaker of American English.</li>
		</ol>
	</div>
</template>

<script setup>
import { defineProps, ref } from 'vue';
const { page, store } = defineProps(['page','store']);
const showNatural = ref(false);
</script>

<style lang="scss" scoped>
	.menu {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: center;
		}

		button {
			margin: .75rem 0;
			min-width: 260px;
		}
	}
	#naturally {
		background-color: $white;
		overflow-y: auto;
		padding: 1.5rem 1rem 3rem;
		position: fixed;
		top: 47px;
		max-width: 510px;
		max-height: 100vh;
		z-index: 9999;

		button {
			background-color: $primary;
			border: 0;
			border-radius: 10px;
			color: $white;
			position: absolute;
			top: 3px; right: 3px;

			padding: .5rem .75rem;
			min-width: 0;
		}

		h4 {
			margin-top: 0;
		}

		li {
			margin-bottom: .5rem;
		}
	}
</style>

<style lang="css">
span.menu-number {
	border: 1px solid black;
	border-radius: 50%;
	position: absolute;
	left: -40px; top: 50%;
	line-height: 30px;
	transform: translateY(-50%);
	width: 30px;
}
</style>