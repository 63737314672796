<template>
	<div id="record-btns" v-if="hasGetUserMedia() && page.title.indexOf('Explanation') == -1">
		<button @click="startRecording" v-if="showMic">
			<i class="fa fa-microphone-alt"></i>
		</button>
		<button @click="stopRecording" v-if="showStop">
			<i class="fa fa-stop"></i>
		</button>
		<button @click="playRecording" :class="{ 'inactive': !showPlay }">
			<i class="fa fa-volume-up flip"></i>
		</button> 
		<button @click="store.dispatch('program/playLastSnd')" :class="{ 'inactive': !showSound }">
			<i class="fa fa-volume-up"></i>
		</button>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

		const store = useStore();
		const page = computed(() => store.getters['program/getPage']);
		let recordedMedia = null;

		const showMic = computed(() => !store.getters['program/getRecordingStatus']);
		const showStop = computed(() => store.getters['program/getRecordingStatus']);
		const showPlay = computed(() => store.getters['program/hasRecording']);
		const showSound = computed(() => store.getters['program/hasSound']);


		const hasGetUserMedia = () => {
			return !!(navigator.mediaDevices.getUserMedia || navigator.webkitGetUserMedia ||
			navigator.mozGetUserMedia || navigator.msGetUserMedia);
		}
	
		// This array stores the recorded media data
		let chunks = [];

		const startRecording = () => {
				store.dispatch('program/startRecording');
/*
				Simple constraints object, for more advanced features see
				<div class="video-container"><blockquote class="wp-embedded-content" data-secret="BFVi21bW6d"><a href="https://addpipe.com/blog/audio-constraints-getusermedia/">Supported Audio Constraints in getUserMedia()</a></blockquote><iframe class="wp-embedded-content" sandbox="allow-scripts" security="restricted" style="position: absolute; clip: rect(1px, 1px, 1px, 1px);" src="https://addpipe.com/blog/audio-constraints-getusermedia/embed/#?secret=BFVi21bW6d" data-secret="BFVi21bW6d" width="600" height="338" title="“Supported Audio Constraints in getUserMedia()” — Pipe Blog" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe></div>

				We're using the standard promise based getUserMedia()
				https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
*/
			navigator.mediaDevices.getUserMedia({ audio: true, video:false }).then(function(mediaStream) {
				// Create a new MediaRecorder instance
        let mediaRecorder = new MediaRecorder(mediaStream);
  
        //Make the mediaStream global
        window.mediaStream = mediaStream;
        //Make the mediaRecorder global
        window.mediaRecorder = mediaRecorder;
  
        mediaRecorder.start();
  
        // Whenever (here when the recorder
        // stops recording) data is available
        // the MediaRecorder emits a "dataavailable" 
        // event with the recorded media data.
        mediaRecorder.ondataavailable = (e) => {
  
            // Push the recorded media data to
            // the chunks array
            chunks.push(e.data);
        };
   
        // When the MediaRecorder stops
        // recording, it emits "stop"
        // event
        mediaRecorder.onstop = () => {

					/* A Blob is a File like object.
					In fact, the File interface is 
					based on Blob. File inherits the 
					Blob interface and expands it to
					support the files on the user's 
					systemThe Blob constructor takes 
					the chunk of media data as the 
					first parameter and constructs 
					a Blob of the type given as the 
					second parameter*/
					let blob = new Blob(
							chunks, {
									type: "audio/mpeg"
							});
					chunks = [];

					recordedMedia = new Audio();
					recordedMedia.controls = false;

					// You can not directly set the blob as 
					// the source of the video or audio element
					// Instead, you need to create a URL for blob
					// using URL.createObjectURL() method.
					let recordedMediaURL = URL.createObjectURL(blob);

					// Now you can use the created URL as the
					// source of the video or audio element
					recordedMedia.src = recordedMediaURL;

					URL.revokeObjectURL(recordedMedia);
        };

			}).catch(function(err) {
					//enable the record button if getUSerMedia() fails
					console.log(err);
			});
		}

		const stopRecording = () => {
			store.dispatch('program/stopRecording');
			// Stop the recording
			window.mediaRecorder.stop();
		
			// Stop all the tracks in the 
			// received media stream
			window.mediaStream.getTracks()
			.forEach((track) => {
					track.stop();
			});
		}

		const playRecording = () => {
			recordedMedia.play();
		}
</script>

<style lang="scss" scoped>
	#record-btns {
		display: flex;
		justify-content: center;
		width: 100%;

		button {
			background-color: $black;
			border: 1px solid $black;
			border-radius: 50%;
			color: white;
			font-size: 2rem;
			height: 60px;
			margin: .25rem;
			padding: 9px;
			min-width: 0;
			width: 60px;

			.fa-stop { color: red; }
			.fa-volume-up.flip { 
				color: green;
				transform: scaleX(-1);
			}
			.fa-volume-up { 
				color: lightskyblue; 
			}

			&.inactive { opacity: .5; }

			&:last-child {
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px
			}
			&:nth-last-child(2) {
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px
			}
		}
	}
</style>