<template>
	<div class="img-explain">
		<page-title />

		<div class="ipa-instruction">
			<small>Fast Track Your Progress</small>
			<button class="instruction-btn" @click="showInstructions = !showInstructions">Instructions</button>
			<aside v-if="showInstructions" class="instruction-pane">
				<p>Identifying your pronunciation errors and the process of elimination to correct them.</p>
				<ol>
					<li>Click on a sound several times, listen intently</li>
					<li>Record your voice and compare it with the native sound</li>
					<li>If your voice matches the native sound, mark as correct</li>
					<li>If your voice does not match, click on the sound again to display the specific lessons and practice exercises below</li>
					<li>After completing the lessons and exercises, return to the IPA chart to record your voice again and compare the sounds</li>
					<li>If the sounds are now in agreement, mark and proceed to the next unchecked sound</li>
					<li>When you are pronouncing the sounds correctly, you will be speaking clearly and easily understood</li>
				</ol>
			</aside>
		</div>
		<figure>
			<figcaption>
				<sound-btn :class="{ 'sel-btn': btn.id == selBtn, 'check-btn': userBtns.indexOf(btn.id) > -1 }"
					v-for="btn in page.buttons" 
					:key="btn.id" :button="btn" 
					@btnClick="sndBtnClick">
					<i class="fa fa-check-circle"></i>
				</sound-btn>
			</figcaption>
		</figure>
		<hr>
		<record-btns></record-btns>
		<h4 class="text-center">Click a button to view related lessons and exercises</h4>
		<div class="btn-complete" v-if="Object.keys(links).length">
			<toggle-switch :checked="btnChecked" @checked="btnCompleted">Mark this sound as correct</toggle-switch>
		</div>
		<br>
		<ul class="link-box">
			<li v-for="sections, type in links" :key="type">
				<div v-if="Object.keys(sections).length > 0">
				<span class="banner">{{ bannerText(type) }}</span><br><hr>
				
				<ul v-for="items, cat in sections" :key="cat">
					<li><strong>{{ cat }}</strong></li>
					<li v-for="link in items" :key="link.id">
						<a @click.prevent="store.dispatch('program/changePage', link.id)" v-html="linkText(link)"></a>
					</li>
				</ul>
				</div>
			</li>
		</ul>
	</div>
</template>

<script setup>
import { defineProps, onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import ToggleSwitch from '@/components/ToggleSwitch';

import RecordBtns from '@/components/RecordBtns';

const store = useStore();
const page = computed(() => store.getters['program/getPage']);
const showInstructions = ref(false);
const selBtn = computed(() => store.getters['program/getIpaBtn']);
const userBtns = computed(() => store.getters['auth/getUserBtns']);
const btnChecked = ref(false);
const links = computed(() => {
	let btn = page.value.buttons.filter(itm => itm.id == selBtn.value);
	let obj = {lesson: {}, practice: {}};
	if(btn.length < 1) return {};
	let pages = btn[0].pages.filter(itm => itm.id != page.id);
	pages.forEach(pg => {
		if(pg.cat_type) {
			if(!obj[pg.cat_type][pg.parent_title]) {
				obj[pg.cat_type][pg.parent_title] = [];
			}
			obj[pg.cat_type][pg.parent_title].push(pg); 
		}
	});
	return obj;
});

const sndBtnClick = rtnBtn => {
	btnChecked.value = userBtns.value.indexOf(rtnBtn.id) > -1;
	store.dispatch('program/setIpaBtn', rtnBtn.id);
};

const btnCompleted = $event => {
	let btnId = selBtn.value;
	let btns = [...userBtns.value];

	// add or remove from userbtns based on whether checked or not
	if($event) {
		if(btns.indexOf(btnId) == -1) {
			btns.push(btnId);
		}
	} else {
		btns = btns.filter(itm => itm != btnId);
	}
	btnChecked.value = $event;
	store.dispatch('auth/saveUserBtns', btns);  // Setup UserBtn context to save
}

const bannerText = type => {
	let str = '';
	switch(type) {
		case 'lesson':
				str = 'Use the lesson pages below to learn how to pronounce this sound.'
			break;
		case 'practice':
				str = 'Use the exercise pages below to practice using this sound.'
			break;
	}
	return str;
}
const linkText = link => `<span>${link.title} - Page ${link.page_sort}</span><i class="fal fa-angle-right"></i>`;

onMounted(() => {
	store.dispatch('program/setIpa', page.value.id);
});
</script>

<style lang="scss" scoped>
	figure {
		margin: 0;
		text-align: center;

		figcaption {
			display: flex;
			flex-wrap: wrap;

			button {
				border-radius: 8px;
				margin: .1rem;
				height: 53px;
				position: relative;
				overflow: visible;
				width: 60px;
				white-space: nowrap;

				i.fa-check-circle {
					color: #00921c;
					display: none;
					position: absolute;
					top: 2px; left: 2px;
				}

				&.check-btn i.fa-check-circle {
					display: inline-block;
				}

				&.sel-btn {
					border: 3px solid $primary;
				}
			}
		}
	}

	.btn-complete {
		align-items: center;
		display: flex;

		span {
			padding-left: 1rem;
		}
	}

	ul {
		list-style: none;
		padding-left: 0;
		ul {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 1rem;

			li {
				flex-basis: 50%;

				a {
					cursor: pointer;
					display: flex;
					justify-content: space-between;
					padding: .4rem 1rem .4rem 0;	
				}

				&:first-child {
					flex-basis: 100%;
				}
			}
		}
	}

	.ipa-instruction {
		display: block;
		padding: .5rem 0;
		text-align: center;

		small {
			display: block;
			margin-bottom: .5rem;
		}

		p {
			margin-bottom: .5rem;
			text-align: left;
		}
		
		ol {
			text-align: left;
			
			li {
				margin-bottom: .5rem;
			}
		}
	}

</style>