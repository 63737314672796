<template>
	<nav v-if="store.getters['auth/isLoggedIn'] && store.getters.getUserMenu">
		<h2>
			<div v-if="user.first_name || user.last_name">
				{{ user.first_name }} {{ user.last_name }}
			</div>
			<small>{{ user.email }}</small>
			<template v-if="user.expiration_date">
				<small v-if="expireCount < 8">Subscription will renew in {{ expireCount }} days</small>
			</template>
		</h2>
		<ul class="user-menu">
			<li v-for="path in paths" :key="path.route">
				<router-link :to="path.route">
					<i :class="'fal fa-'+path.icon"></i> {{ path.text }}
				</router-link>
			</li>
		</ul>
		<a @click.prevent="logout">Logout <i class="fal fa-sign-out"></i></a>
	</nav>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';

const store = useStore();
const router = useRouter();
const user = computed(() => store.getters['auth/getUser']);
const payments = computed(() => store.getters['auth/getPayments']);
const paths = computed(() => {
	let vals = [
		{route: '/progress', text: 'Progress', icon: 'chart-bar'},
		{route: '/profile', text: 'Profile', icon: 'user-circle'},
		{route: '/billing', text: 'Billing', icon: 'wallet'},
		{route: '/payments', text: 'Payments', icon: 'receipt'},
		//{route: '/promos', text: 'Promos', icon: 'gift'},
		//{route: '/purchase', text: 'Purchase', icon: 'store'}
    {route: '/subscription', text: 'Subscription', icon: 'store'},
    {route: '/support', text: 'Support', icon: 'question-circle'}
	];

	if(user.value.invite_count != null) {
		vals.splice(2, 0, {route: '/invite', text: 'Invite', icon: 'envelope'});
	}
	return vals;
});
const expireCount = computed(() => {
	let date1 = new Date();
	let date2 = new Date(user.value.expiration_date);
	let diffTime = Math.abs(date2 - date1);
	let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	return diffDays;
})

const logout = async () => {
	store.dispatch('setUserMenu', false);
	store.dispatch('auth/logout');
	await AuthService.logout();
	router.push('/login');
};

</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	transform: translateY(-100%);
}

nav {
	background-color: $black;
	min-width: 200px;
	position: absolute;
	top: 48px; right: 0;
	z-index: 9998;

	h2 {
		color: $white;
		font-size: 1.2rem;
		text-align: center;
		margin: 0;
		padding: 1rem;
		position: relative;

		small {
			font-size: .9rem;
			
			& + small {
				border-top: 1px solid $white;
				display: block;
				font-size: .8rem;
				margin-top: .25rem;
				padding-top: .25rem;
			}
		}

		&:after {
			background-color: $white;
			content: '';
			height: 2px;
			position: absolute;
			bottom: 0; left: 50%;
			transform: translateX(-50%);
			width: 40px;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding-left: 0;

		li {
			a {
				color: $white;
				display: inline-block;
				font-size: 1rem;
				padding: .6rem 2.2rem;
				text-decoration: none;
				width: 100%;

				i {
					color: $primary;
					margin-right: .5rem;
				}

				&.router-link-active {
					position: relative;

					&:after {
						background-color: $primary;
						content: '';
						height: 2px;
						position: absolute;
						bottom: 10px;
						transform: translateX(-50%);

						width: 25px;
					}
				}
			}

			&:first-child {
				padding-top: .5rem;
			}
			&:last-child {
				padding-bottom: .75rem;
			}
		}

		& + a {
			background-color: $primary;
			color: $white;
			display: inline-block;
			padding: .75rem;
			text-align: center;
			width: 100%;
		}
	}
}
</style>