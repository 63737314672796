<template>
	<h2>
		<img src="/imgs/logo_bg.png" alt="AAP Logo" width="30">
		American Accent Program
		<small>&copy;</small>
	</h2>
	<main>
		<section class="form register">
			<form @submit.prevent="registerInvite">
				<h1>
					Accept Invite - Create Account<br>
					<small>Have an account already? <router-link to="/login">Sign In</router-link></small>
					<hr>
					<div :class="{notice: true, success: validCode || validCode === null}">
						<template v-if="validCode !== null">
							{{ validCode ? 'Valid Invitation' : 'Invalid Invitation' }}
						</template>
						<mini-load :show="validCode === null"></mini-load>
					</div>
				</h1>
				<template v-if="validCode">
					<label>
						<strong>First Name<sup>*</sup></strong>
						<input class="form-control" type="text" id="first_name" required v-model="v$.firstName.$model">
					</label>
					<label>
						<strong>Last Name<sup>*</sup></strong>
						<input class="form-control" type="text" id="last_name" required v-model="v$.lastName.$model">
					</label>
					<label>
						<strong>Email<sup>*</sup></strong><br>
						<input class="form-control" type="email" id="email" disabled :value="emailAddr">
					</label>
					<label>
						<strong>Phone</strong><br>
						<input class="form-control" type="tel" id="phone" v-model="formData.phone">
					</label>
					<label :class="{error: v$.password.$error}">
						<strong>Password<sup>*</sup></strong><br>
						<input class="form-control" type="password" id="password" minlength="6" required v-model="v$.password.$model">
						<small v-if="v$.password.$error">{{ v$.password.$errors[0]?.$message }}</small>
					</label>
					<label :class="{error: v$.password_confirmation.$error}">
						<strong>Confirm Password<sup>*</sup></strong><br>
						<input class="form-control" type="password" id="password_confirm" minlength="6" required v-model="v$.password_confirmation.$model">
						<small v-if="v$.password_confirmation.$error">{{ v$.password_confirmation.$errors[0]?.$message }}</small>
					</label>
					<label class="full">
						<strong>Native Language:</strong><br>
						<input class="form-control" type="text" id="native_language" v-model="formData.nativeLanguage">
					</label>
					<div class="full" style="display:flex;justify-content:space-between;">
						<small><sup>*</sup> required</small>
						<button id="submit-invite-register" type="submit">
							<mini-load :show="loaders.submit">
								<i class="fal fa-user-plus"></i> &nbsp; Create Account
							</mini-load>
						</button>
					</div>
				</template>
				<msg-box></msg-box>
			</form>
		</section>
	</main>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'

import MsgBox from '@/components/MsgBox.vue';
import AuthService from '@/services/AuthService.js';
import UserService from '@/services/UserService.js';
import PageService from '@/services/PageService.js';

const store = useStore();
const router = useRouter();
const route = useRoute();
const { withAsync, withMessage } = helpers;

const formData = reactive({
  firstName: '',
  lastName: '',
  phone: '',
  password: '',
  password_confirmation: '',
  nativeLanguage: '',
	inviteCode: route.params.token || null
});
const emailAddr = ref('');
const validCode = ref(null);
const fieldsFilled = ref(false);
const loaders = ref({
	submit: false
});

const checkCode = async () => {
	let resp = await UserService.validateInvite( {token: formData.inviteCode} );
	loaders.validCode = resp.success;
	return resp;
}

const rules = {
  firstName: { required }, // Matches state.firstName
  lastName: { required }, // Matches state.lastName
  password: {
    required: withMessage('Password is required', required),
    minLength: withMessage('Must be at least 6 characters in length', minLength(6))
  }, // Matches state.password
  password_confirmation: {
    required: withMessage('Password Confirm is required', required),
    minLength: withMessage('Must be at least 6 characters in length', minLength(6)),
    v: withMessage('Must match password', value => value === formData.password)
  }, // Matches state.password_confirmation
}

const v$ = useVuelidate(rules, formData);

const registerInvite = async () => {
	loaders.submit = true;

	formData.email = emailAddr.value;

	try {
		await AuthService.registerInvite(formData);
	} catch(err) {
    loaders.submit = false;
		await store.dispatch('setMsg', { resp: err.response });
	}
  await PageService.syncMenus();
  await store.dispatch('auth/syncUser');

  await store.dispatch('setMsg', { msg: { text: 'Your submission was completed', status: true } });

  loaders.submit = false;
  await router.push('/thank-you');
};

onMounted(async () => {
	if ( formData.inviteCode ) {
		let { data } = await checkCode();
		validCode.value = data.success;
		emailAddr.value = data.email || '';
	}

	await PageService.syncStartPage();
});
</script>

<style lang="scss" scoped>

h2 {
	color: $white;
	font-size: 2rem;
	margin-top: 1rem;
	text-align: center;


	small {
		color: $primary;
		display: inline-block;
		font-size: 1rem;
		transform: translateY(-9px);
	}

	img {
		transform: translateY(3px);
	}
}

p {
  font-size: .8rem;
  text-align: center;
  grid-column: span 2;
}
	form {
		justify-content: normal;
		margin: auto -.25rem;

    sup {
      color: $secondary;
    }

		label {
			grid-column: auto;
			padding: 0 .25rem;
		}

    .full {
      grid-column: span 2;
    }

		@media screen and (max-width: 480px) {
			label {
				grid-column: span 2;
			}
		}
	}
	
	.notice {
		background-color: lighten($error, 65%);
		border: 1px solid $error;
		border-radius: 5px;
		color: $error;
		font-size: 1rem;
		font-weight: bold;
		margin: 0;
		padding: .5rem;
		text-align: center;

		&.success {
			background-color: lighten($success, 65%);
			color: $success;
		}
	}

	fieldset {
		display: flex;
		flex-wrap: wrap;
		grid-column: span 2;
		justify-content: space-around;
		margin: 0;
		margin-bottom: 1rem;
		padding-top: 1rem;
		width: 100%;

		legend {
			font-weight: bold;
		}

    & ~ label {
      position: relative;

      small {
        position: absolute;
        top: 100%;
      }
    }
	}
</style>
