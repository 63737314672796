<template>
	<h2>
		<img src="/imgs/logo_bg.png" alt="AAP Logo" width="30">
		American Accent Program
		<small>&copy;</small>
	</h2>
  <small>Your checkout is secure with <i class="fab fa-2x fa-paypal"></i></small>
	<main>
		<section class="form register">
			<form>
				<h1>Create Account</h1>
				<fieldset>
					<legend>Membership:</legend>
          <div class="plans">
            <label v-for="plan in plans" :key="plan.id"
                   :class="{active: (selectedPlan ? selectedPlan.id : '') === plan.id}">
              <input type="radio" v-model="selectedPlan" :value="plan">
              <span v-html="planTime(plan)"></span>
            </label>
          </div>
          <small>(cancel anytime)</small>
				</fieldset>
				<label>
					<strong>First Name<sup>*</sup></strong>
					<input class="form-control" type="text" id="first_name" required v-model="v$.firstName.$model">
				</label>
				<label>
					<strong>Last Name<sup>*</sup></strong>
					<input class="form-control" type="text" id="last_name" required v-model="v$.lastName.$model">
				</label>
				<label :class="{error: v$.email.$error && v$.email.$errors.length}">
					<strong>Email<sup>*</sup></strong><br>
					<input class="form-control" type="email" id="email" required v-model="v$.email.$model">
          <small v-if="v$.email.$error">{{ v$.email.$errors[0]?.$message }}</small>
				</label>
				<label>
					<strong>Phone</strong><br>
					<input class="form-control" type="tel" id="phone" v-model="formData.phone">
				</label>
				<label :class="{error: v$.password.$error}">
					<strong>Password<sup>*</sup></strong><br>
					<input class="form-control" type="password" id="password" minlength="6" required v-model="v$.password.$model">
          <small v-if="v$.password.$error">{{ v$.password.$errors[0]?.$message }}</small>
				</label>
				<label :class="{error: v$.password_confirmation.$error}">
					<strong>Confirm Password<sup>*</sup></strong><br>
					<input class="form-control" type="password" id="password_confirm" minlength="6" required v-model="v$.password_confirmation.$model">
          <small v-if="v$.password_confirmation.$error">{{ v$.password_confirmation.$errors[0]?.$message }}</small>
				</label>
				<label class="full">
					<strong>Native Language:</strong><br>
					<input class="form-control" type="text" id="native_language" v-model="formData.nativeLanguage">
				</label>
        <div class="full">
          <small><sup>*</sup> required</small>
          <br><br>
        </div>

				<div class="payment-section" v-if="planExists">
          <div class="payment-cover" v-if="v$.$invalid"></div>
					<br>
          <paypal-buttons
            :formData="formData"
            :billing="{plans, selectedPlan}">
          </paypal-buttons>
				</div>

        <p>
          A receipt from “American Accent Program” will be sent to your email after payment.<br>
          You can cancel your subscription at anytime.<br>
          After payment, you’ll be redirected to our “Getting Stared” Module.
        </p>

				<router-link to="/login"><i class="fa fa-angle-left"></i> Back</router-link>
				<msg-box></msg-box>
			</form>
		</section>
	</main>
</template>

<script setup>
import { computed, onMounted, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'

import PaypalButtons from '@/components/PaypalButtonsRegister.vue';
import MsgBox from '@/components/MsgBox.vue';
import AuthService from '@/services/AuthService.js';
import BillingService from '@/services/BillingService.js';
import PageService from '@/services/PageService.js';

const store = useStore();
const router = useRouter();
const route = useRoute();
const { withAsync, withMessage } = helpers;

const formData = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  password_confirmation: '',
  nativeLanguage: '',
});
const fieldsFilled = ref(false);

const plans = ref([]);
const selectedPlan = ref(null);
const planExists = computed(() => selectedPlan.value !== null);
const loaders = ref({
	submit: false
});

const emailTimer = ref(null);
const emailTaken = async () => {
  return new Promise(resolve => {
    try {
      if ( emailTimer.value ) {
        clearTimeout( emailTimer.value );
        emailTimer.value = null;
      }

      emailTimer.value = setTimeout( async () => {
        if ( formData.email ) {
          resolve( !(await AuthService.emailTaken( formData.email )) );
        }
        resolve( false );
      }, 300 );
    } catch(e) {
      resolve( false );
    }
  })
}

const rules = {
  firstName: { required }, // Matches state.firstName
  lastName: { required }, // Matches state.lastName
  email: {
    required: withMessage('Email Address is required', required),
    email: withMessage('Invalid Email Address', email),
    isTaken: withMessage('Email Address Unavailable', withAsync(emailTaken)),
    $lazy: true
  }, // Matches state.email
  password: {
    required: withMessage('Password is required', required),
    minLength: withMessage('Must be at least 6 characters in length', minLength(6))
  }, // Matches state.password
  password_confirmation: {
    required: withMessage('Password Confirm is required', required),
    minLength: withMessage('Must be at least 6 characters in length', minLength(6)),
    v: withMessage('Must match password', value => value === formData.password)
  }, // Matches state.password_confirmation
}

const v$ = useVuelidate(rules, formData)

const planTime = plan => {
  let rtn = '';
  let cycles = plan.billing_cycles;

  let price = parseFloat(cycles[0].pricing_scheme.fixed_price.value).toFixed(2);
  let time = cycles[0].frequency.interval_count;
  let type = cycles[0].frequency.interval_unit;

  rtn = `$${price} / ${type.toLowerCase()}`;
  if(cycles.length === 1 && cycles[0].total_cycles === 1) {
    rtn = `$${price} / ${type.toLowerCase()}<br> <small>(one-time payment)</small>`;
  }

  if(cycles.length > 1) {
    rtn = `$${price} first ${type.toLowerCase()}`;
    let price2 = parseFloat(cycles[1].pricing_scheme.fixed_price.value).toFixed(2);
    let time2 = cycles[1].frequency.interval_count;
    let type2 = cycles[1].frequency.interval_unit;
    rtn += `<br><small class="old-price">then<br>$${price2} / ${type2.toLowerCase()}</small>`;
  }

  return rtn;
};

onMounted(async () => {
	plans.value = await BillingService.plans();

	if(route.params.subscribe) {
		selectedPlan.value = plans.value.at(plans.value.length > 1 ? 1 : 0);
	} else {
		selectedPlan.value = plans.value.at(0);
	}
	await PageService.syncStartPage();
});
</script>

<style lang="scss" scoped>

p {
  font-size: .8rem;
  text-align: center;
  grid-column: span 2;
}
	form {
		justify-content: normal;
		margin: auto -.25rem;
    padding-bottom: 2.5rem;

    sup {
      color: $secondary;
    }

		label {
			grid-column: auto;
			padding: 0 .25rem;
		}

    .full {
      grid-column: span 2;
    }

		@media screen and (max-width: 480px) {
			label {
				grid-column: span 2;
			}
		}
	}
	

	fieldset {
		display: flex;
		flex-wrap: wrap;
		grid-column: span 2;
		justify-content: space-around;
		margin: 0;
		margin-bottom: 1rem;
		padding-top: 1rem;
		width: 100%;

		legend {
			font-weight: bold;
		}

    & ~ label {
      position: relative;

      small {
        position: absolute;
        top: 100%;
      }
    }
	}

  .plans {
    display: flex;
    flex-direction: column;
    column-gap: .5rem;
    justify-content: center;
    margin-bottom: .25rem;
    width: 100%;

    label {
      align-items: center;
      border: 2px solid #eee;
      display: flex;
      flex-basis: 50%;
      opacity: .6;
      min-width: 30%;
      padding: 1.5rem 1.25rem 2.25rem;
      position: relative;
      text-align: center;
      transition: all .4s;

      &:after {
        border: 2px solid $secondary;
        border-radius: 25px;
        color: $secondary;
        content: '\02713';
        opacity: 0;
        padding: 0 .25rem;
        position: absolute;
        top: 5px; left: 5px;
        transition: all .4s;
      }

      &:before {
        background: #eee;
        content: "";
        position: absolute;
        bottom: 0; left: 0;
        clip-path: polygon(50% 0, 0% 100%, 100% 100%);
        height: 20px;
        transition: all .2s ease;
        width: 100%;
      }

      &.current:before {
        border: 2px solid $secondary;
        border-radius: 25px;
        color: $secondary;
        font-size: .75rem;
        content: 'current';
        opacity: 0;
        padding: 0 .5rem;
        position: absolute;
        bottom: 5px; right: 5px;
        transition: all .4s;
      }

      input { display: none; }
      span {
        display: block;
        font-size: 1.35rem;
        width: 100%;

        ::v-deep(small) {
          display: block;
          font-size: .9rem;
        }
      }

      i {
        display: inline-block;
        font-size: 1.05rem;
        margin-top: 1rem;

        &.strike-out {
          text-decoration: $secondary line-through;
        }

        &.discount-amount {
          color: $secondary;
          position: absolute;
          bottom: 10px; left: 50%;
          transform: translateX(-50%);
        }
      }

      &.active {
        border: 2px solid $secondary;
        opacity: 1;
        position: relative;

        &:after, &:before {
          opacity: 1;
        }

        &:before {
          background: $secondary;
        }
      }
    }

    @media (min-width: 380px) {
      flex-direction: row;
    }
  }

	h2 {
		color: $black;
		font-size: 2rem;
		margin-top: 1rem;
    margin-bottom: 0;
		text-align: center;


		small {
			color: $secondary;
			display: inline-block;
			font-size: 1rem;
			transform: translateY(-9px);
		}

		img {
			transform: translateY(3px);
		}

    & + small {
      color: $black;
      display: block;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      text-align: center;

      i {
        transform: translateY(25%);
      }
    }
	}

	.payment-section {
		grid-column: span 2;
    position: relative;

    .payment-cover {
      background: rgba(255,255,255,.5);
      bottom: 0; left: 0;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 9999;

      &:before {
        border-bottom: 1px solid $black;
        content: 'Fill out the form to continue';
        display: inline-block;
        position: absolute;
        top: 0; left: 50%;
        transform: translateX(-50%);
        text-align: center;
      }
    }

		.discount {
			display: flex;
			margin: 1rem auto 2rem;
			width: 100%;

			button {
				flex-grow: 1;
				margin-left: 2rem;
				white-space: nowrap;
			}
		}

		.tagline {
			grid-column: span 2;
		}

		.price {
			font-size: 3rem;
			text-align: center;

      small {
        font-size: 1rem;
        opacity: .4;
      }
		}

		.free-btn {
			display: inline-block;
			margin: 0 auto;
		}
	}
</style>
