<template>
	<div>
		<h2>
			<img src="/imgs/logo.png" alt="AAP Logo" width="30">
			American Accent Program
			<small>&copy;</small>
		</h2>
		<main>
			<section class="form">
				<form @submit.prevent="login">
					<h1>{{ forgot ? 'Send Reset Password Link' : 'User Login' }}</h1>
					<form-field type="email" v-model="email">Email:</form-field>
					<form-field v-if="!forgot" type="password" v-model="password">Password:</form-field>
					<div class="remember-container">
						<toggle-switch :checked="remember" @checked="e => remember = e" /> <span>Remember Me?</span>
					</div>
					<a href="#" @click.prevent="forgot = !forgot">{{ !forgot ? 'Forgot Password?' : 'Cancel' }}</a>
					<button class="button" v-if="!forgot" type="submit">Login <i class="fal fa-sign-in"></i></button>
					<button class="button" v-else @click="forgotPassword" type="button">Send Reset Link <i class="fal fa-paper-plane"></i></button>
					<msg-box></msg-box>
				</form>
			</section>
		</main>
		<footer>
			<p>Don't have an account? Register <router-link to="/register">here</router-link>.</p>
		</footer>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import FormField from '@/components/FormField';
import ToggleSwitch from '@/components/ToggleSwitch';
import MsgBox from '@/components/MsgBox.vue'
import AuthService from '@/services/AuthService.js'
import PageService from '../services/PageService';

const store = useStore();
const router = useRouter();
const email = ref('');
const password = ref('');
const remember = ref(false);
const forgot = ref(false);

async function login() {
	const credentials = { email: email.value, password: password.value };

	let rem = remember.value;
	if(rem) {
		credentials.remember = 1;
	}

	try {
		let resp = await AuthService.login(credentials);
		let user = resp.data.user;
		let payments = resp.data.payments;
		let page = resp.data.page;
		let progress = resp.data.progress;
		await store.dispatch('auth/login', { user, payments });
		await store.dispatch('program/syncProgress', { progress });
		store.dispatch('program/setMap');
		if(user.page_id) {
			await PageService.savePage(page);
			store.dispatch('program/changePage', page.id);
		}
		router.push('/');
	} catch (err) {
		store.dispatch('setMsg', { resp: err.response })
	}
}
async function forgotPassword() {
	try {
		let resp = await AuthService.forgotPassword(this.emailAddr);
		store.dispatch('setMsg', { resp });
	} catch (err) {
		store.dispatch('setMsg', { resp: err.response });
	}
}
</script>

<style lang="scss" scoped>
	h2 {
		color: $white;
		font-size: 2rem;
		margin-top: 1rem;
		text-align: center;


		small {
			color: $primary;
			display: inline-block;
			font-size: 1rem;
			transform: translateY(-9px);
		}

		img {
			transform: translateY(3px);
		}
	}

	.remember-container {
		align-items: center;
		display: flex;
		margin-bottom: 1rem;
		
		& > span {
			white-space: nowrap;
		}
	}
</style>