<template>
	<header v-if="store.getters['auth/isLoggedIn']">
		<button id="menu-btn" title="Menu" @click="router.push('/map')">
			<i class="fal fa-list"></i>
		</button>

		<button class="home-btn" @click="homeClick">
			<img src="/imgs/logo.png" alt="AAP Logo" width="30">
		</button>

		<span>
			<button @click="store.dispatch('program/showInstructions')">
				<i :class="'fal ' + (store.getters['program/getShowInstructions'] ? 'fa-times' : 'fa-question-circle')"></i>
			</button>

			<button @click="store.dispatch('setUserMenu', !store.getters['getUserMenu'])">
				<i :class="'fal ' + (store.getters.getUserMenu ? 'fa-times'  : 'fa-user')"></i>
			</button>
		</span>
	</header>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store = useStore();
const router = useRouter();
const route = useRoute();

const homeClick = () => {
	if(store.getters['program/getShowInstructions']) {
		store.dispatch('program/showInstructions', false);

	} else if(route.name === 'home') {
		store.dispatch('program/changePage', 'home');

	} else {
		router.push('/');
	}
}
</script>

<style lang="scss" scoped>
header {
	background-color: $black;
  display: flex;
	padding: .5rem .25rem;
	position: fixed;
	top: 0; left : 50%;
	transform: translateX(-50%);
	width: 100%;
	z-index: 9998;

  & > * {
    flex-basis: 33.3333%;
  }

	& > span {
    text-align: right;
		white-space: nowrap;

		i.fal {
			width: 24px;
		}
	}
}
button {
	background-color: transparent;
	border: 0;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-size: 1.5rem;

	&:first-child {
		text-align: left;
	}
	&:last-child {
		text-align: right;
	}
}

.home-btn {
	img {
		width: 25px;
	}
}
</style>