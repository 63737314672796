<template>
	<div class="instruction-panel" v-if="store.getters['program/getShowInstructions']">
		<carousel ref="instCarousel" :items-to-show="1">
			<slide v-for="slide, idx in slides" :key="idx">
				<div class="carousel__item" :class="{ 'wordsonly': slide.words }">
					<figure :class="{ 'coord': (slide.pos ?? false) || slide.words }">
						<img v-if="slide.img" :src="slide.img" :alt="idx">
						<figcaption v-html="slide.html" :style="slide.pos"></figcaption>
					</figure>
				</div>
			</slide>

			<template #addons>
				<navigation />
				<pagination />
			</template>
		</carousel>
		<button class="button" @click="store.dispatch('program/showInstructions', false)">
			<i class="fal fa-times"></i> Close Instructions
		</button>
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

const store = useStore();
const page = computed(() => store.getters['program/getPage']);
const instCarousel = ref(null);

// Slides for each template type and main parts
let path = '/imgs/instructions/';
let header = {
	img: path+'header.png',
	html: `List icon shows map of the program.<br>The app icon will take you to the program from any other page.<br>Shows instructions for page.<br>User icon allows you to view your account settings and progress.`
};
let desc = {
	img: path+'description.png',
	html: 'Button play audio of text.<br>Text helps you understand the lesson.' 
};
let microphone = {
	img: path+'microphone.png',
	html: 'Must allow use of your microphone to properly utilize the program.'
};
let record = {
	img: path+'recordbtns.png',
	html: 'Record your voice, stop recording, play your recorded voice, compare your recording to native sound'
};
let footer = {
	img: path+'footer1.png',
	html: 'Last menu, Previous/Next page, Select page number'
};


// template specific
let ImgExplain = [{
	img: path+'imgexplain.png',
	html: `Title of Lesson is listed at the top.<br>The image moves to show how a sound is produced.<br>Buttons will play native sound or sentences.`
}];
let Ipa = [{
	img: path+'ipalinks.png',
	html: 'Click each sound button to play native sounds, record your voice and compare your recording to the native sound.  If the recording and sound are similar then mark the sound as completed to keep track of which sounds need work.  Lessons and Practice modules for each sound are listed below.'
}];
let Menu = [{
	img: path+'menubtns.png',
	html: 'Menu buttons are used to navigate to each lesson.'
}];
let MixedBtns = [{
	img: path+'mixedbtns.png',
	html: 'Plays native sounds, words or sentences to teach pronounciation'
}];
let RiseFallSen = [{
	img: path+'risefallsen.png',
	html: 'Plays comparison sounds between rising and falling inflections'
}];
let SentenceBtns = [{
	img: path+'sentencebtns.png',
	html: 'Plays audio for each sentence to show different sounds throught the sentence.'
}];
let SoundCompare = [{
	img: path+'soundcompare.png',
	html: 'Plays audio to compare similar sounds to show subtle differences.'
}];
let WordBtns = [{
	img: path+'wordbtns.png',
	html: 'Plays audio for each word see how each word sounds.'
}];
let WordCompare = [{
	img: path+'wordcompare.png',
	html: 'Plays audio to compare similar words to show differences in how to pronounce.'
}];

const instructions = {
	ImgExplain,  
	Ipa,  
	Menu,
	MixedBtns, 
	RiseFallSen, 
	SentenceBtns,  
	SoundCompare, 
	WordBtns, 
	WordCompare  
};

// getting started slides
const startedPath = '/imgs/started/';
const started = [
	{
		html: `<h4>Recommended Method of Study (1/2)</h4>
			<p>The American Accent Program is designed to create advanced speakers of American English - individuals who are easily understood and fluent with an American Accent</p>
			<p>This is accomplished by:</p>
			<ol>
				<li>Mastering the pronunciation of the vowels and consonants of American English</li>
				<li>Mastering the intonation of American English</li>
			</ol>
			<p>The temptation might be to skip 'Speaking Clearly' and start with 'Speaking Fluently'; however, this is not the most effective or successful strategy.</p>
			<ol>
				<li>
					<p>Start with Speaking Clearly: It is vitally important to pronounce the vowels and consonants of American English correctly.</p>
					<p>Many non-native speakers are speaking English daily without realizing they are not easily understood, especially when communicating over the phone</p>
					<p>Use the Vowel and Consonant IPA charts to verify if you are pronouncing the sounds correctly. The AAP is designed to allow you to quickly identify and correct any errors.</p>
				</li>
			</ol>
			`,
			words: true,
			pos: { top: '0' }
	},
	{
		html: `<h4>Recommended Method of Study (2/2)</h4>
			<ol start="2">
				<li>
					<p>Continue with Speaking Fluently</p>
					<p>The Intonation module is designed to perfect your American Accent, using the natural stress, rythm and melody of American English.</p>	
				</li>
				<li>
					<p>Finish with Speaking Naturally</p>
					<p>This module offers advanced practice to demonstrate mastery of both speaking clearly and speaking fluently as an advanced speaker of American English.</p>
				</li>
			</ol>`,
			words: true,
			pos: { top: '0' }
	},
	{
		img: startedPath+'sitemap_icon.webp',
		html: 'Program Map: Click to view an outline mapping of the entire program.',
		pos: { top: '12.5%' }
	},
	{
		img: startedPath+'logo.webp',
		html: 'Click to return to the program.',
		pos: { top: '14.5%' }
	},
  {
    img: startedPath + 'help_icon.webp',
    html: 'Click to view instructions for the current program page.',
    pos: { top: '15.5%' }
  },
  {
    img: startedPath + 'profile_icon.webp',
    html: 'Click to view Account Information: Progress, Profile, Billing, Payments and Purchases.',
    pos: { top: '14.25%' }
  },
  {
    img: startedPath + 'lesson_title.webp',
    html: 'Title of Lesson: Shows you the current location in the lesson.',
    pos: { top: '28.25%' }
  },
  {
    img: startedPath + 'graphic.webp',
    html: 'Graphic moves to show how the sound is produced. Moves when the sound button is clicked (see next).',
    pos: { top: '52%' }
  },
  {
    img: startedPath + 'sound_buttons.webp',
    html: 'Sound Buttons: Click to hear how the sound is produced.',
    pos: { bottom: '64.5%' }
  },
  {
    img: startedPath + 'instructions_button.webp',
    html: 'Paragraph Button: Click to hear the lesson instructions and, for advanced practice, the option to listen and record your own paragraphs for comparison.',
    pos: { bottom: '55%' }
  },
  {
    img: startedPath + 'instructions_text.webp',
    html: 'Lesson instructions explain how to use the different positions of your mouth to produce the sound correctly.',
    pos: { bottom: '60%' }
  },
  {
    img: startedPath + 'record_button.webp',
    html: 'Recording Button: Click to record while pronouncing a sound(s) or sentence(s), click again to stop recording.',
    pos: { bottom: '21%' }
  },
  {
    img: startedPath + 'playback_button.webp',
    html: 'Playback Button: Click to hear your recorded sound(s).',
    pos: { bottom: '27.25%' }
  },
  {
    img: startedPath + 'native_sound_button.webp',
    html: 'Native Speaker Recording: Click back and forth between your recording and native recording to compare for correct matching.',
    pos: { bottom: '20.75%' }
  },
  {
    img: startedPath + 'back_to_menu_button.webp',
    html: 'Click to return to the last menu page.',
    pos: { bottom: '15.5%' }
  },
  {
    img: startedPath + 'previous_button.webp',
    html: 'Click to return to the previous lesson page.',
    pos: { bottom: '13%' }
  },
  {
    img: startedPath + 'next_button.webp',
    html: 'Click to go to the next lesson page.',
    pos: { bottom: '13.5%' }
  },
  {
    img: startedPath + 'page_selection.webp',
    html: 'Shows current page of the lesson. Click to select a different page in the lesson.',
    pos: { bottom: '15%' }
  }
];

const slides = computed(() => {
	let slug = page.value.slug ?? '';
	if(slug === 'home') return started;

	let template = page.value.template ?? 'Menu';
	let hasDesc = page.value.description_id != null;
	let rtn = instructions[template].concat([header]);

	if(hasDesc) rtn.push(desc);
	if(template != 'Menu') rtn.push(microphone, record);
	rtn.push(footer);
	return rtn;
});

onMounted(() => {
	if(page.value.slug != 'home') {
		instCarousel
	}
});
</script>

<style lang="scss">
	.instruction-panel {
		background-color: rgba($black, .95);
		justify-content: flex-start !important;
		max-width: none !important;
    padding: 60px 0 20px 0;
		position: fixed !important;
		top: 0; right: 0; bottom: 0; left: 0;
		z-index: 9997;

		figure {
			position: relative;

			img {
				display: block;
				margin-bottom: 1rem;
			}
			figcaption {
				color: $white;
				font-size: .8rem;
				line-height: 1.2;
				text-align: left;
			}

			&.coord {
				figcaption {
					background-color: rgba($black, .95);
					border: 2px solid rgba($white, .8);
					font-size: 1.1rem;
					padding: 1.5rem;
					position: absolute;
					width: 100%;

					@media screen and (max-width: 360px) {
						font-size: .9rem;
					}
				}
			}
		}

		.carousel__item {
			img {
				max-width: 100%;
				max-height: 100%;
			}

			&.wordsonly {
				height: 100%;
				position: absolute;
				top: 0;
				max-width: 400px;
				width: 100%;

				figure {
					height: 100%;
					margin: 0;
				}
				figcaption {
					background-color: $black;
					font-size: .9rem;
					max-height: 100%;
					overflow-y: auto;
					padding: .75rem;

					h4 { 
						margin-top: 0;
						text-align: center;
					}
				}
			}
		}

		.carousel__pagination {
			padding-left: 0;
		}
		
		.carousel {
			button {
				background-color: $primary;
				&.carousel__pagination-button {
					background-color: lighten($primary, 20%);

					&--active {
						background-color: $primary;
					}
				}
				&.carousel__prev--in-active,
				&.carousel__next--in-active {
					display: none;
				}
			}
			@media screen and (max-width: 545px) {
					& > button {
						transform: translate(0, -50%) !important;
						
						&.carousel__next {

						}

						&.carousel__prev {

						}
					}
			}
		}


		button.button {
			width: 200px !important;

			i.fal {
				margin-right: .5rem;
			}
		}
	}
</style>