<template>
	<div class="progress-page">
		<main>
			<section>
				<h2 class="text-center">
					Invite Users:<br>
					<small v-if="invitesLoaded">You have <span :class="{ 'green': inviteCount > 10, 'yellow': inviteCount > 5 && inviteCount <= 10, 'red': inviteCount <= 5 }">{{ inviteCount }}</span> invite{{ inviteCount != 1 ? 's' : '' }} left</small>
				</h2>
				<div class="text-center">
					<small v-if="inviteCount <= 5">
						Contact Carol Ford <a href="mailto:carol@americanaccentprogram.com">carol@americanaccentprogram.com</a> to purchase more invtes
					</small>
				</div>
				<form v-if="invitesLoaded && inviteCount > 0" @submit.prevent="sendInvite">
					<div>
						<form-field type="email" v-model="inviteEmail" required></form-field>
						<button><mini-load :show="loader"><i class="fal fa-credit-card"></i> &nbsp; Send Invite</mini-load></button>
					</div>
					<msg-box></msg-box>
				</form>
			</section>
			<section>
				<h2 class="text-center">Sent Invites</h2>
				<table class="table invite-list">
					<thead>
						<tr>
							<th>Email</th>
							<th>Invited</th>
							<th>Status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="inv in invites" :key="inv.id">
							<td>{{ inv.email }}</td>
							<td>{{ format( new Date(inv.created_at), 'MM/dd/yyyy') }}</td>
							<td>
								{{ $filters.capitalize(inv.status) }}
							</td>
							<td>
								<button class="deleteBtn" title="Cancel Invite"
									@click.prevent="cancelInvite(inv.id)" 
									v-if="inv.status == 'pending'"
								>
									<i class="fa fa-times	"></i>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</section>
		</main>
	</div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { format } from 'date-fns';

import MsgBox from '@/components/MsgBox';
import FormField from '@/components/FormField';
import UserService from '@/services/UserService';

const store = useStore();
const loader = ref(false);

const user = computed(() => store.getters['auth/getUser']);
const inviteCount = computed(() => user.value.invite_count - invites.value.filter(inv => inv.status == 'pending' || inv.status == 'accepted').length);
const inviteEmail = ref('');
const invites = ref([]);
const invitesLoaded = ref(false);

const sendInvite = async () => {
	loader.value = true;
	try {
		let resp = await UserService.sendInvite({email: inviteEmail.value});
		store.dispatch('setMsg', { resp });
		let resp2 = await UserService.getInvites();
		invites.value = resp2.data.invites || [];
	} catch (err) {
		store.dispatch('setMsg', { resp: err.response });
	}
	loader.value = false;
	inviteEmail.value = '';
};

const cancelInvite = async (id) => {

	if(!confirm('Are you sure you want to cancel this invite?')) return;

	try {
		let resp = await UserService.cancelInvite(id);
		if(resp.data.success) {
			invites.value = invites.value.forEach(inv => {
				if(inv.id == id) inv.status = 'cancelled';
			});
		}
		store.dispatch('setMsg', { resp });
	} catch (err) {
		store.dispatch('setMsg', { resp: err.response });
	}
};

onMounted(async () => {
	let resp = await UserService.getInvites();
	invites.value = resp.data.invites || [];
	invitesLoaded.value = true;
});
</script>

<style lang="scss" scoped>
	.progress-page {
		background-color: white;
	}
	h2 {
		small {
			border: 1px solid $black;
			display: inline-block;
			font-size: 0.65em;
			margin-top: .5rem;
			padding: .5rem;

			span {
				padding: .25rem;
				border: 1px solid transparent;
				border-radius: 4px;
				
				// use bootstrap alert colors
				&.green {
					background-color: #dff0d8;
					border-color: #d6e9c6;
					color: #3c763d;
				}
				&.yellow {
					background-color: #fcf8e3;
					border-color: #faebcc;
					color: #8a6d3b;
				}
				&.red {
					background-color: #f2dede;
					border-color: #ebccd1;
					color: #a94442;
				}
			}
		}
	}

	form {
		margin-top: 1rem;
		grid-template-columns: 1fr;
		
		div {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			label {
				flex-grow: 1;
			}

			button {
				margin-left: 1rem;
				margin-bottom: 1rem;
			}
		
		}
	}

	.invite-list {
		text-align: center;
		width: 100%;

		thead {
			th {
				border-bottom: 1px solid $black;
			}
		}

		.deleteBtn {
			background-color: transparent;
			border: none;
			color: $primary;
			outline: 0;
		}
	}
</style>