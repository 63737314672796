<template>
  <div class="home">
		<template v-if="showProgram">
			<component :is="markRaw(lookup[page.template ?? 'Menu'])" :page="page" :store="store" />
			<record-btns v-if="((page.buttons ?? []).length > 0 || page.template == 'Info' || page.description_id) && page.template != 'Ipa' && page.template != 'PatternSample'"></record-btns>
			<a class="ipa-back" 
				v-if="ipaId > 0 && ipaId != page.id && (page.buttons.length > 0 && page.buttons.filter(itm => itm.id == ipaBtn).length)" 
				@click.prevent="store.dispatch('program/changePage', ipaId)"
			><i class="fal fa-angle-left"></i> Back to IPA
			</a>
		</template>
		<expire-msg v-else />

		<div class="invite-overlay" v-if="invite !== null">
			<div v-if="!inviteResp" class="invite-modal">
				<h2>Invited by {{ invite.user.first_name }}</h2>
				<p>{{ invite.user.first_name }} {{ invite.user.last_name }} has invited you to join The American Accent Program <sup>&copy;</sup> for 1 year free. Click the button below to accept the invitation.</p>
	
				<div class="btn-actions">
					<button class="accept-btn" @click.prevent="answerInvite(true)">
						<i class="fal fa-user-plus"></i> &nbsp; Accept
					</button>
					<button class="decline-btn" @click.prevent="answerInvite(false)">
						<i class="fal fa-user-times"></i> &nbsp; Decline
					</button>
				</div>
			</div>
			<div v-else class="invite-response">
				Your invitation has been {{ inviteResp }}
			</div>
		</div>
  </div>
</template>

<script setup>
import { computed, ref, markRaw, onMounted } from 'vue';
import { useStore } from 'vuex';

import PageService from '@/services/PageService';
import UserService from '@/services/UserService';

import RecordBtns from '@/components/RecordBtns';
import ExpireMsg from '@/components/ExpireMsg';

import Menu from '@/templates/Menu';
import Ipa from '@/templates/Ipa';
import PatternSample from '@/templates/PatternSample';
import ImgExplain from '@/templates/ImgExplain';
import SoundCompare from '@/templates/SoundCompare.vue';
import WordBtns from '@/templates/WordBtns.vue';
import Info from '@/templates/WordBtns.vue';
import SentenceBtns from '@/templates/SentenceBtns.vue';
import MixedBtns from '@/templates/MixedBtns.vue';
import WordCompare from '@/templates/WordCompare.vue';
import RiseFallSen from '@/templates/RiseFallSen.vue';

const store = useStore();
const page = computed(() => store.getters['program/getPage']);
const ipaId = computed(() => store.getters['program/getIpa']);
const ipaBtn = computed(() => store.getters['program/getIpaBtn']);
const showProgram = computed(() => store.getters['auth/isActive']);
const invite = ref(null);
const inviteResp = ref('');

const lookup = {
	Menu,
	Ipa,
	PatternSample,
	ImgExplain,
	SoundCompare,
	WordBtns,
	Info,
	SentenceBtns,
	MixedBtns,
	WordCompare,
	RiseFallSen
};

const answerInvite = async choice => {
	if(choice) {
		await UserService.acceptInvite(invite.value.id);
		inviteResp.value = 'accepted';
		await store.dispatch('auth/userStatus');
		await PageService.initPages();
	} else {
		await UserService.declineInvite(invite.value.id);
		inviteResp.value = 'declined';
	}
	setTimeout(() => {
		invite.value = null;
		inviteResp.value = '';
	}, 4000);
}

const fetchInvite = async () => {
	let myInvite = await UserService.getMyInvite();
	if(myInvite.data.invite) {
		invite.value = myInvite.data.invite;
	}
}
fetchInvite();

onMounted(async () => {
	let status = await store.dispatch('auth/userStatus');
	if(!status) {
		if(localStorage.getItem('expDate')) {
			PageService.deleteData();
			localStorage.removeItem('expDate');
		}
	} else {
		await PageService.initPages();
	}
});
</script>

<style lang="scss">
	.home {
		background-color: white;
		position: relative;

		& > * {
			padding: 0 2rem;
			width: 100%;
		}

		.ipa-back {
			cursor: pointer;
			display: inline-block;
			padding: .75rem 1.5rem;
		}

		img.explain {
			display: block;
			margin: 0 auto;
			width: 100%;

			&.alt {
				max-width: 300px;
			}
		}

		button {
			background-color: #eee9db;
			border: 1px solid #cfaf89;
			border-radius: 15px;
			color: $black;
			cursor: pointer;
			display: inline;
			font-size: 1rem;
			font-weight: bold;
			margin: 0;
			padding: 14px;
			position: relative;
			text-align: center;
			text-decoration: none;
			transition: position 0.3s ease-in-out;
		}

		.invite-overlay {
			background-color: rgba(0, 0, 0, 0.5);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9999;

			.invite-modal {
				background: $white;
				border: 1px solid $black;
				padding: 1rem;
				position: absolute;
				top: 50%; left: 50%;
				transform: translate(-50%, -50%);
				width: 95%;

				h2 {
					margin-top: 0;
				}

				.btn-actions {
					display: flex;
					justify-content: space-around;
					margin-top: 1.25rem;

					.accept-btn {
						background-color: #dff0d8;
						border: 1px solid #d6e9c6;
						border-radius: 15px;
						color: #3c763d;
						cursor: pointer;
						font-size: 1rem;
						font-weight: bold;
						padding: 14px;
						text-align: center;
						transition: background-color 0.3s ease-in-out;

						&:hover {
							background-color: #d0e9c6;
						}
					}
					.decline-btn {
						background-color: #f2dede;
						border: 1px solid #ebccd1;
						border-radius: 15px;
						color: #a94442;
						cursor: pointer;
						font-size: 1rem;
						font-weight: bold;
						padding: 14px;
						text-align: center;
						transition: background-color 0.3s ease-in-out;

						&:hover {
							background-color: #ebcccc;
						}
					}
				}
			}
		}
	}
</style>