<template>
	<div class="progress-page">
		<main>
			<section class="form">
				<h2 class="text-center">Manage your plan:</h2>

        <mini-load :show="initLoad"></mini-load>
        <template v-if="!initLoad">
        <div class="unsub-notice" v-if="subStatus !== 'ACTIVE'">
          You do not have an active subscription.<br>Please choose a plan below to get started.
        </div>
				<div class="plans">
					<label v-for="plan in plans" :key="plan.id"
                 :class="{active: (selectedPlan ? selectedPlan.id : '') == plan.id, current: user.plan_id == plan.id}">
						<input type="radio" v-model="selectedPlan" :value="plan"> 
						<span v-html="planTime(plan)"></span>
            <i class="poly"></i>
					</label>
				</div>

        <div class="next-bill">
          <template v-if="subStatus === 'ACTIVE'">
            <strong>Next Autopay Date:</strong> <span>{{ format(new Date(user.expiration_date), 'PP') }}</span>
          </template>
          <template v-else-if="((new Date(user.expiration_date) > new Date()))">
            <strong>Expiration Date:</strong> <span>{{ format(new Date(user.expiration_date), 'PP') }}</span>
          </template>
        </div>

				<paypal-buttons v-if="subStatus !== 'ACTIVE' && selectedPlan" :billing="{plans, selectedPlan}"></paypal-buttons>
        <button v-if="(subStatus === 'ACTIVE' || subStatus === 'SUSPENDED') && selectedPlan && selectedPlan.id !== user.plan_id"
                class="button secondary" type="button" @click="updateSubscription">Update Your Subscription</button>

        <hr>
        <button v-if="subStatus === 'ACTIVE'" class="button cancel" type="button" @click="cancelSubscription">Cancel Your Subscription</button>
        <msg-box></msg-box>
        </template>
			</section>
		</main>
	</div>
</template>

<script setup>
import { onMounted, computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { format } from 'date-fns';

import BillingService from '@/services/BillingService';
import PageService from '@/services/PageService';

// import UserHeader from '@/components/UserHeader';
import MsgBox from '@/components/MsgBox';
import PaymentData from '@/components/PaymentData';
import PaypalButtons from '@/components/PaypalButtons';
import MiniLoad from "@/components/MiniLoader.vue";

const store = useStore();
const initLoad = ref(true);
const loaders = ref(false);

const plans = ref([]);
const selectedPlan = ref(null);
const subStatus = ref('');
const user = computed(() => store.getters['auth/getUser']);

const planTime = plan => {
  let rtn = '';
  let cycles = plan.billing_cycles;

  let price = parseFloat(cycles[0].pricing_scheme.fixed_price.value).toFixed(2);
  let time = cycles[0].frequency.interval_count;
  let type = cycles[0].frequency.interval_unit;

  rtn = `$${price} / ${type.toLowerCase()}`;
  if(cycles.length === 1 && cycles[0].total_cycles === 1) {
    rtn = `$${price} / ${type.toLowerCase()}<br> <small>(one-time payment)</small>`;
  }

  if(cycles.length > 1) {
    rtn = `$${price} first ${type.toLowerCase()}`;
    let price2 = parseFloat(cycles[1].pricing_scheme.fixed_price.value).toFixed(2);
    let time2 = cycles[1].frequency.interval_count;
    let type2 = cycles[1].frequency.interval_unit;
    rtn += `<br><small class="old-price">then<br>$${price2} / ${type2.toLowerCase()}</small>`;
  }

  return rtn;
};

const updateSubscription = async () => {
  loaders.value = true;
  try {
    let conf = confirm('Are you sure you want to update your subscription?');
    if(!conf) return;

    let resp = await BillingService.updateSubscription({ planId: selectedPlan.value.id });
    await store.dispatch('auth/syncUser');
    await store.dispatch('setMsg', { msg: { text: 'Your subscription has been updated', status: true } });
  } catch(err) {
    await store.dispatch('setMsg', { resp: err.response });
  }
  loaders.value = false;
};
const cancelSubscription = async () => {
  loaders.value = true;
  try {
    let conf = confirm('Are you sure you want to cancel your subscription?');
    if(!conf) return;

    await BillingService.cancelSubscription();
    await store.dispatch('auth/syncUser');
    await store.dispatch('setMsg', { msg: { text: 'Your subscription has been cancelled', status: true } });
  } catch(err) {
    await store.dispatch('setMsg', { resp: err.response });
  }
  loaders.value = false;
};

onMounted(async () => {
  await store.dispatch('auth/syncUser');
	plans.value = await BillingService.plans();
  let userPlan = await user.value.plan_id;
  selectedPlan.value = { id: userPlan };
  subStatus.value = await BillingService.checkSubscriptionStatus();

  initLoad.value = false;
});
</script>

<style lang="scss" scoped>
  .unsub-notice {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .next-bill {
    align-items: center;
    border: 1px solid rgba($black, .125);
    background-color: lighten(lightgrey, 10%);
    color: rgba($black, .8);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: center;

    strong {
      padding: .25rem 1rem;
    }
    span {
      font-size: 1.25rem;
      padding: .25rem 1rem;
    }
  }

  .secondary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

	.progress-page {
		background-color: white;
		
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

		fieldset {
			display: flex;
			width: 100%;
		}

		.payments {
			padding: 1rem;
			max-height: 300px;
			overflow-y: auto;
		}

    .plans {
      display: flex;
      flex-direction: column;
      column-gap: .5rem;
      justify-content: center;
      margin-bottom: 1rem;
      width: 100%;

      label {
        align-items: center;
        border: 2px solid #eee;
        display: flex;
        flex-basis: 50%;
        opacity: .6;
        min-width: 30%;
        padding: 1.5rem 1.25rem 2.25rem;
        position: relative;
        text-align: center;
        transition: all .4s;

        &:after {
          border: 2px solid $secondary;
          border-radius: 25px;
          color: $secondary;
          content: '\02713';
          opacity: 0;
          padding: 0 .25rem;
          position: absolute;
          top: 5px; left: 5px;
          transition: all .4s;
        }

        .poly {
          background: #eee;
          content: "";
          position: absolute;
          bottom: -1px; left: 0;
          clip-path: polygon(50% 0, 0% 100%, 100% 100%);
          height: 25px;
          transition: all .2s ease;
          width: 100%;
          z-index: 0;
        }

        &.current:before {
          background-color: $white;
          border: 2px solid grey;
          border-radius: 25px;
          color: grey;
          font-size: .75rem;
          content: 'current';
          padding: 0 .5rem;
          position: absolute;
          bottom: 5px; right: 5px;
          transition: all .4s;
          z-index: 1;
        }

        input { display: none; }
        span {
          display: block;
          font-size: 1.35rem;
          width: 100%;

          ::v-deep(small) {
            display: block;
            font-size: .9rem;
          }
        }

        i {
          display: inline-block;
          font-size: 1.05rem;
          margin-top: 1rem;

          &.strike-out {
            text-decoration: red line-through;
          }

          &.discount-amount {
            color: $secondary;
            position: absolute;
            bottom: 10px; left: 50%;
            transform: translateX(-50%);
          }
        }

        &.active {
          border: 2px solid $secondary;
          opacity: 1;
          position: relative;

          &:after, &:before {
            opacity: 1;
          }

          &.current:before {
            background-color: $white;
            border: 2px solid $secondary;
            color: $secondary;
          }

          .poly {
            background: $secondary;
          }
        }
      }

      @media (min-width: 380px) {
        flex-direction: row;
      }
    }

		.tagline {
			border: 1px solid $secondary;
			border-radius: 3px;
			color: $secondary;
			padding: 1rem;
			text-align: center;
		}

		.discount {
			display: flex;
			margin: 1rem auto 2rem;
			max-width: 80%;

			button {
				flex-grow: 1;
				margin-left: 2rem;
				white-space: nowrap;
			}
		}

		.free-btn {
			display: inline-block;
			margin: 0 auto;
		}
	}
</style>