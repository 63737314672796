<template>
  <div class="profile-page">
    <main>
      <h2>Contact Support</h2>
      <section class="form" @submit.prevent>

        <hr>
        <p>Please use the form below to report any issues you might be experiencing with the program. We're here to assist you, and including a screenshot of the problem can greatly aid our support team in providing you with a solution.</p>
        <hr>
        <br>

        <form-field type="email" v-model="formData.email">Email</form-field>

        <label>
          <strong>Message</strong><br>
          <textarea class="form-control" rows="6" v-model="formData.message"></textarea>
        </label>

        <label>
          <strong>Attachment</strong><br>
          <input class="form-control" type="file" @change="attachFile" accept="image/*">
        </label>

        <br>
        <button class="button" type="submit" @click.prevent="sendSupportRequest"><mini-load></mini-load> Send Message</button>
        <msg-box></msg-box>
      </section>
    </main>
  </div>
</template>

<script setup>
import { reactive, computed, ref } from 'vue';
import { useStore } from 'vuex';
import UserService from '@/services/UserService';
import FormField from '@/components/FormField';
import MsgBox from '@/components/MsgBox';

const store = useStore();
const loader = ref(false);
const user = computed(() => store.getters['auth/getUser']);

const formData = reactive({
  email: user.value.email,
  message: '',
  file: null
});

const attachFile = event => {
  formData.file = event.target.files[0];
}

const sendSupportRequest = async () => {
  let data = new FormData();
  data.append('email', formData.email);
  data.append('message', formData.message);
  if(formData.file) {
    data.append('file', formData.file);
  }
  loader.value = true;
  try {
    let resp = await UserService.requestSupport(data);
    store.dispatch('setMsg', { resp });
  } catch (err) {
    store.dispatch('setMsg', { resp: err.response });
  }
  loader.value = false;
};
</script>

<style lang="scss" scoped>
.profile-page {
  background-color: white;

  h2 {
    margin-top: 0;
    text-align: center;
  }

  button[type=submit] {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>