<template>
	<footer>
		<template v-if="route.meta.public || (route.name ?? 'home') !== 'home'">
			<small>{{ new Date().getFullYear() }} American Accent Program <sup>&copy;</sup></small>
		</template>
		<template v-else>
			<button class="back-btn" @click="store.dispatch('program/backPage')" v-visible="page.slug != 'home'">
				<i class="fal fa-step-backward"></i>
			</button>
			<button class="prev-btn" @click="store.dispatch('program/prevPage')" v-visible="page.prev_id"><i class="fal fa-chevron-left"></i></button>
			<button class="next-btn" @click="store.dispatch('program/nextPage')" v-visible="page.next_id"><i class="fal fa-chevron-right"></i></button>
			<select class="sel-nav" @change="store.dispatch('program/selectPage', $event.target.value)" v-if="page.siblings && page.siblings.length > 1">
				<option disabled>Pages</option>
				<option v-for="opt in page.siblings" :value="opt.id" :key="opt.id" :selected="opt.id == page.id ? true : false">
					{{ opt.page_sort }} of {{ page.max }}
				</option>
			</select>
		</template>
	</footer>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store = useStore();
const page = computed(() => store.getters['program/getPage']);
const route = useRoute();
</script>

<style lang="scss" scoped>
	footer {
		background-color: $black;
		display: flex;
		justify-content: space-between;
		padding: .5rem .25rem;
		margin: 0 auto;
		max-width: 500px;
		width: 100%;
		z-index: 2;

		& > * {
			flex-grow: 1;
			width: 25%;
		}

		small {
			color: $white;
			display: block;
			text-align: center;

			sup {
				color: $primary;
			}
		}
	}
	button {
		background-color: transparent;
		border: 0;
		color: white;
		font-size: 1.5rem;

		&:first-child {
			text-align: left;
		}
	}
	select {
		text-align: center;
		margin-right: .5rem;
	}

	@media screen and (max-width: 500px) {
		footer {
			position: fixed;
			bottom: 0; left: 0;
			width: 100%;
		}
	}
</style>

