<template>
	<div class="progress-page">
		<main>
			<section class="form">
				<h2 class="text-center">Payment History:</h2>
				<div v-if="payments.length" class="payments">
					<payment-data v-for="payment in payments" :key="payment.id" :payment="payment"></payment-data>
				</div>	
				<div v-else>
					<br><br><br>
          <h3 class="text-center" v-if="loader">Loading payment history... <br><br><mini-load :show="loader"></mini-load></h3>
					<h3 class="text-center" v-else>No payment history yet...</h3>
				</div>
			</section>
		</main>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import BillingService from "@/services/BillingService";

import PaymentData from '@/components/PaymentData';

const store = useStore();
const payments = ref([]);
const loader = ref(false);

onMounted(async () => {
  loader.value = true;
  payments.value = await BillingService.transactions();
  loader.value = false;
});
</script>

<style lang="scss" scoped>
	.progress-page {
		background-color: white;

		.payments {
			padding: 1rem;
			overflow-y: auto;
		}
	}
</style>