<template>
  <div class="thank-you">
    <main>
      <section>
        <h1>Welcome to the<br> American Accent Program!</h1>
        <p>Thank you for joining the American Accent Program. Please wait a few seconds for the full program to load...</p>
        <mini-load :show="true" :size="'2rem'"></mini-load>
      </section>
    </main>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PageService from '@/services/PageService';
import MiniLoad from "@/components/MiniLoader.vue";

const store = useStore();
const router = useRouter();

onMounted( async () => {
  let status = await store.dispatch('auth/userStatus');

  if(status) {
    await PageService.initPages();
  }

	setTimeout(() => {
		router.push('/');
	}, 6000);
});
</script>

<style lang="scss" scoped>
.thank-you {
  display: flex;

  main {
    padding: 1rem;
  }
}

h1 {
  font-size: 2.25rem;
	text-align: center;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
h2 {
	color: $white;
	font-size: 2rem;
	margin-top: 1rem;
	text-align: center;


	small {
		color: $primary;
		display: inline-block;
		font-size: 1rem;
		transform: translateY(-9px);
	}

	img {
		transform: translateY(3px);
	}
}

p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

p div {
	display: inline-block;
}
</style>